import React, { Fragment, useEffect, useRef } from "react";
import lottie from "lottie-web";
import ScrollTop from "./utils/ScrollTop";

const AboutMe = () => {
  const frontendGIF = useRef(null);
  const backendGIF = useRef(null);
  const programmingGIF = useRef(null);
  const androidGIF = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    lottie.loadAnimation({
      container: frontendGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Frontend.json"),
    });
    lottie.loadAnimation({
      container: backendGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Backend.json"),
    });
    lottie.loadAnimation({
      container: programmingGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Programming.json"),
    });
    lottie.loadAnimation({
      container: androidGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Android.json"),
    });
  }, []);
  return (
    <Fragment>
      <ScrollTop />
      <div className="container">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="heading h1 text-center mt-3"
        >
          What I Do?
          <div className="about-text mt-3">
            I'm a software development enthusiast with the goal of building
            applications and libraries to solve complex issues and reduce
            redundancies in daily activities. I have completed my Bachelors in
            Computer Engineering from Pune Institute of Computer Technology
            (University of Pune) and I'm currently pursuing a Master's degree in
            Computer Science from New York University, Tandon School of
            Engineering.
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="mt-5 heading h1 text-center"
        >
          Programming
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="programmingGIF"
              ref={programmingGIF}
            ></div>
          </div>
          <div className="col-12 col-md-6">
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="description"
            >
              <div className="h2 category mt-5">Programming Languages</div>
              <div className="icons mt-4">
                <div className="iconify" data-icon="logos:c-plusplus"></div>
                <div className="iconify" data-icon="logos:python"></div>
                <div className="iconify" data-icon="logos:javascript"></div>
                <div className="iconify" data-icon="logos:java"></div>
              </div>
              <div className="key-points mt-4">
                <ul>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> I've worked with
                    C/C++, Python, JavaScript,Java in academia as well as in a
                    professional setting.
                  </li>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> I primarily
                    utilize Python for Problem solving, Data Structures and
                    Algorithms.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
          className="mt-5  heading h1 text-center"
        >
          Web Development
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
          className="row"
        >
          <div className="col-12 col-md-6 order-12">
            <div className="h2 category mt-5 ">Frontend Technologies</div>
            <div className="description">
              <div className="icons mt-4">
                <div
                  className="iconify"
                  data-icon="vscode-icons:file-type-html"
                ></div>
                <div
                  className="iconify"
                  data-icon="vscode-icons:file-type-css"
                ></div>
                <div className="iconify" data-icon="logos:javascript"></div>

                <div
                  className="iconify"
                  data-icon="vscode-icons:file-type-reactjs"
                ></div>
                <div className="iconify" data-icon="logos:redux"></div>
                <div className="iconify" data-icon="logos:bootstrap"></div>
              </div>
              <div className="key-points mt-4">
                <ul>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> Developing
                    responsive static web pages using HTML, CSS and Bootstrap.
                  </li>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> Using React.Js
                    and associated NPM libraries to make beautiful dynamic
                    websites and applications.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1">
            <div className="frontendGIF" ref={frontendGIF}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="backendGIF"
              ref={backendGIF}
            ></div>
          </div>
          <div className="col-12 col-md-6">
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="h2 category mt-5"
            >
              Backend Technologies
              <div className="icons mt-4">
                <div className="iconify" data-icon="logos:nodejs-icon"></div>
                <div className="iconify mongo" data-icon="cib:mongodb"></div>
                <div className="iconify" data-icon="logos:firebase"></div>
                <div className="iconify" data-icon="logos:postgresql"></div>
                <div
                  className="iconify"
                  data-icon="vscode-icons:file-type-npm"
                ></div>
                <div className="iconify" data-icon="logos:yarn"></div>
              </div>
              <div className="key-points mt-4">
                <ul>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> Database
                    Management, API Development and Deployment, and Intergration
                    with Frontend.
                  </li>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> I've utlized a
                    variety of Node packages as well as SQL/NoSQL Databases for
                    development and deployment.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="mt-5 heading h1 text-center"
        >
          Mobile App Development
        </div>
        <div className="row">
          <div className="col-12 col-md-6 order-12">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="description"
            >
              <div className="h2 category mt-5">Technologies</div>
              <div className="icons mt-4">
                <div
                  className="iconify"
                  data-icon="vscode-icons:file-type-reactts"
                ></div>
                <div
                  className="iconify"
                  data-icon="logos:typescript-icon"
                ></div>
              </div>
              <div className="key-points mt-4">
                <ul>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> Developing
                    cross-platform mobile applications using React-Native.
                  </li>
                  <li>
                    <i className="fa fa-arrow-right mt-4"></i> I've utlized
                    React-Native to build healthcare products, as well as
                    personal projects.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1">
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="androidGIF"
              ref={androidGIF}
            ></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutMe;
