import React, { Fragment, useEffect, useRef } from "react";
import lottie from "lottie-web";
import ScrollTop from "./utils/ScrollTop";
import ResumeIcon from "../assets/img/note.png";

const Home = () => {
  const homeGIF = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: homeGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/HomePage.json"),
    });
  }, []);

  return (
    <Fragment>
      <ScrollTop />
      <div className="home">
        <div className="container">
          {/* Main Home */}
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="row greeting"
          >
            {/* Greetings Text */}
            <div className="greeting-para col-12 col-md-6">
              <div className="greeting-text margin_b-res mt-6 mb-5">
                <div className="h3 greeting-text__hi">Hi there!</div>
                <div className="h1 greeting-text__name">I'm Pranav Mohril</div>
                <div className="greeting-text__bio text-center">
                  MS CS Grad Student at{" "}
                  <a
                    href="https://engineering.nyu.edu/"
                    target="_blank"
                    className="nyu-violet"
                    rel="noreferrer"
                  >
                    <span className="underline-animation">NYU</span>
                  </a>{" "}
                  and
                </div>
                <div className="greeting-text__bio text-center">
                  Full Stack Developer
                </div>
              </div>
              {/* Social Media */}
              <div className="d-flex justify-content-center ml-4">
                <a
                  href="http://www.linkedin.com/in/pranav-mohril"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="social fa fa-linkedin-square"></i>
                </a>
                <a
                  href="http://www.github.com/techquazar"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="social fa fa-github-square"></i>
                </a>
                <a href="mailto:pranavmohril@gmail.com">
                  <i className="social fa fa-envelope-square"></i>
                </a>
              </div>
              {/* Resume Button */}
              <div className="d-flex justify-content-center margin-res mt-5">
                <a
                  href="https://drive.google.com/file/d/1bJdnQUV2wq1PR7s5_iHK_ujLh_d52uaH/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button type="button" className="custom-btn">
                    <img src={ResumeIcon} height={20} width={20} alt="Resume" />{" "}
                    See My Resume
                  </button>
                </a>
              </div>
            </div>
            {/* Greetings SVG */}
            <div className="col-12 col-md-6">
              <div className="homeGIF" ref={homeGIF}></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
