import React, { Fragment, useEffect, useRef } from "react";
import lottie from "lottie-web";
import Iss1 from "../assets/img/Iss_1.png";
import Iss2 from "../assets/img/Iss_2.png";
import Iss3 from "../assets/img/Iss_3.png";
import ScrollTop from "./utils/ScrollTop";
import NFSS1 from "../assets/img/NFSS_1.png";
import NFSS2 from "../assets/img/NFSS_2.png";
import NFSS3 from "../assets/img/NFSS_3.png";
import Shadow1 from "../assets/img/Shadow_1.png";
import Shadow2 from "../assets/img/Shadow_2.png";
import Shadow3 from "../assets/img/Shadow_3.png";
import Shadow4 from "../assets/img/Shadow_4.png";
import IO1 from "../assets/img/io_1.png";
import IO2 from "../assets/img/io_2.png";
import IO3 from "../assets/img/io_3.png";
import IO4 from "../assets/img/io_4.png";

const Projects = () => {
  const projectGIF = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: projectGIF.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/img/Project.json"),
    });
  }, []);

  return (
    <Fragment>
      <ScrollTop />
      <div className="container">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="row pr-header"
        >
          <div className="col-12 col-md-6 order-12">
            <div className="projectGIF" ref={projectGIF}></div>
          </div>
          <div className="align col-12 col-md-6 order-1">
            <div className="pr-heading h1 text-center">Projects</div>
            <div className="justify-text-res lead mt-4 text-center">
              The projects make best use of lastest technologies and are based
              on real-world scenarios. I've made use of different technologies
              for different projects.
            </div>
          </div>
        </div>
        {/* Istriwala */}
        <div className="row mt-6">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="col-12 col-md-6 order-1"
          >
            <div>
              <p className="h1 title">Istriwala</p>
            </div>
            <div>
              <p className="justify-text-res lead">
                This is mobile application made for laundry owners. The app
                features necessities like creating an order, filtering the
                orders, revenue dashboard, and many more.
              </p>
            </div>
            <div className="icons">
              <div
                className="iconify"
                data-icon="vscode-icons:file-type-reactts"
              ></div>
              <div className="iconify" data-icon="logos:redux"></div>
              <div className="iconify" data-icon="logos:javascript"></div>
              <div className="iconify" data-icon="logos:firebase"></div>
            </div>
            <div className="mt-8">
              <a
                href="https://drive.google.com/file/d/1akfR_ZWwaIdL8nDjPx7OTo3_9_-BXJyy/view"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="pr-btn">
                  Link
                </button>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-12 d-flex align-items-center justify-content-center">
            <div className="d-flex justify-content-center">
              <img
                data-aos="flip-right"
                data-aos-duration="1000"
                src={Iss1}
                alt="Istriwala"
                className="pr-image-istri mx-1"
              />
              <img
                data-aos="flip-right"
                data-aos-duration="1000"
                src={Iss2}
                alt="Istriwala"
                className="pr-image-istri mx-1  "
              />
              <img
                data-aos="flip-right"
                data-aos-duration="1000"
                src={Iss3}
                alt="Istriwala"
                className="pr-image-istri mx-1"
              />
            </div>
          </div>
        </div>
        {/* NFSS */}
        <div className="row mt-6">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="col-12 col-md-6 order-1"
          >
            <div>
              <p className="h1 title">Network-Wide File Search System</p>
            </div>
            <div>
              <p className="justify-text-res lead">
                Collaborated with a team to design and develop a file system for
                LAN devices based on a distributed architecture as an
                alternative to traditional network file system. Implemented a
                semantic search based on metadata to enhance search operation.
              </p>
            </div>
            <div className="icons">
              <div
                className="iconify"
                data-icon="vscode-icons:file-type-reactts"
              ></div>
              <div className="iconify" data-icon="logos:python"></div>
              <div className="iconify" data-icon="logos:java"></div>
              <div className="iconify" data-icon="logos:sqlite"></div>
            </div>
            <div className="mt-8">
              <a
                href="https://github.com/orgs/NetworkwideFileSearch/repositories"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="pr-btn">
                  Link
                </button>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-12 d-flex align-items-center justify-content-center">
            <div
              id="portfolioCarousel2"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#portfolioCarousel2"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#portfolioCarousel2" data-slide-to="1"></li>

                <li data-target="#portfolioCarousel2" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={NFSS1}
                    alt="NFSS"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={NFSS2}
                    alt="NFSS"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={NFSS3}
                    alt="NFSS"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#portfolioCarousel2"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#portfolioCarousel2"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        {/* Shadow-Mapping */}
        <div className="row mt-6">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="col-12 col-md-6 order-1"
          >
            <div>
              <p className="h1 title">Urban-Navigation using Shadow Mapping</p>
            </div>
            <div>
              <p className="justify-text-res lead">
                Collaborated with a team to optimize Urban Navigation for
                Pedestrians with Photosensitivity through Shadow Mapping using
                NYC Open Data (Course: Big Data).
              </p>
            </div>
            <div className="icons">
              <div className="iconify" data-icon="logos:python"></div>
              <div className="iconify" data-icon="logos:spark"></div>
            </div>
            <div className="mt-8">
              <a
                href="https://drive.google.com/drive/folders/1cd1Z4pLNxyVSYUJ5PjZSYWkAZ1bpFAve?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="pr-btn">
                  Link
                </button>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-12 d-flex align-items-center justify-content-center">
            <div
              id="portfolioCarousel3"
              className="carousel slide"
              style={{ width: "70%" }}
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#portfolioCarousel3"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#portfolioCarousel3" data-slide-to="1"></li>

                <li data-target="#portfolioCarousel3" data-slide-to="2"></li>
                <li data-target="#portfolioCarousel3" data-slide-to="3"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={Shadow1}
                    alt="ShadeTrail"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={Shadow2}
                    alt="ShadeTrail"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={Shadow3}
                    alt="ShadeTrail"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={Shadow4}
                    alt="ShadeTrail"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#portfolioCarousel3"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#portfolioCarousel3"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>

        {/* IO Performance */}
        <div className="row mt-6">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="col-12 col-md-6 order-1"
          >
            <div>
              <p className="h1 title">I/O Performance Statistics</p>
            </div>
            <div>
              <p className="justify-text-res lead">
                Evaluated the effects of block sizes, caches and the cost of
                system calls for Linux(Course: Operating Systems).
              </p>
            </div>
            <div className="icons">
              <div className="iconify" data-icon="logos:c"></div>
              <div className="iconify" data-icon="logos:python"></div>
            </div>
            <div className="mt-8">
              <a
                href="https://github.com/TechQuazar/I-O-Performance-Statistics"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="pr-btn">
                  Link
                </button>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-12 d-flex align-items-center justify-content-center">
            <div
              id="portfolioCarousel4"
              className="carousel slide"
              style={{ width: "70%" }}
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#portfolioCarousel4"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#portfolioCarousel4" data-slide-to="1"></li>

                <li data-target="#portfolioCarousel4" data-slide-to="2"></li>
                <li data-target="#portfolioCarousel4" data-slide-to="3"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={IO1}
                    alt="IO"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={IO2}
                    alt="IO"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={IO3}
                    alt="IO"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    src={IO4}
                    alt="IO"
                    className="d-block w-100 pr-image-istri mx-1"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#portfolioCarousel4"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#portfolioCarousel4"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* More Projects */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="d-flex justify-content-center mt-6 mb-6"
      >
        <a
          href="https://github.com/techquazar"
          target="_blank"
          rel="noreferrer"
        >
          <button type="button" className="mt-res-2 custom-btn">
            More Projects
          </button>
        </a>
      </div>
    </Fragment>
  );
};

export default Projects;
